import PropTypes from 'prop-types'
import { Group, Center, ActionIcon } from '@mantine/core'
import 'dayjs/locale/es-mx'
import { MdOutlineInsertPhoto, MdOutlineFileDownload } from 'react-icons/md'

function Value({ file }) {
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        borderRadius: theme.radius.sm
      })}
    >
      <MdOutlineInsertPhoto size={14} style={{ marginRight: 5 }} />
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 200,
          display: 'inline-block'
        }}
      >
        {file.key}
      </span>
      <ActionIcon
        onClick={() => window.open(file.location)}
        style={{ marginLeft: 5 }}
        variant="filled"
      >
        <MdOutlineFileDownload size={14} />
      </ActionIcon>
    </Center>
  )
}

Value.propTypes = {
  file: PropTypes.object
}

Value.defaultProps = {
  file: {}
}

export default function FilesGroup({ files }) {
  return (
    <Group spacing="sm" py="xs">
      {files.map((file) => (
        <Value file={file} key={file.key} />
      ))}
    </Group>
  )
}

FilesGroup.propTypes = {
  files: PropTypes.array
}

FilesGroup.defaultProps = {
  files: []
}

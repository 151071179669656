import { useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
// @mantine-ui
import {
  Button,
  Grid,
  Switch,
  TextInput,
  Textarea,
  Select,
  MultiSelect,
  Radio,
  Box,
  Center,
  Divider,
  Checkbox,
  Alert,
  Badge,
  ActionIcon,
  FileInput,
  NumberInput
} from '@mantine/core'
// @icons
import { MdUploadFile } from 'react-icons/md'
import { FiAlertCircle, FiX } from 'react-icons/fi'
// @validations
import validationSchema from '../validations'
// @hooks
import useAttributesMutation from '../hooks/useAttributesMutation'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import fetchAll from '../../../shared/hooks/useFetchAll'
import attributesTypeOptions from '../../../utils/attributesType'
import AttributesFormulaView from './AttributesFormulaView'
import AttributesTableView from './AttributesTableView'
import AttributesNumericRangeView from './AttributesNumericRangeView'
import AttributesDateRangeView from './AttributesDateRangeView'
import AttributesNumericView from './AttributesNumericView'
import AttributesDefaultListView from './AttributesDefaultListView'
import {
  setAttributeDataToUpdate,
  setModalShow
} from '../../../store/attributesSlice'

function AttributesForm() {
  const { addAttributes, updateAttributes } = useAttributesMutation()
  const { dataToEdit, modalShow } = useSelector((state) => state.attributes)
  const [filesSelected, setfilesSelected] = useState([])
  const dispatch = useDispatch()
  const { data } = fetchAll({
    key: 'client-types',
    filters: {
      is_active: true,
      insurance_type: true,
      limit: 'all'
    },
    customConfig: {
      refetchOnMount: true,
      enable: modalShow
    }
  })

  const formik = useFormik({
    initialValues: {
      name: dataToEdit ? dataToEdit.name : '',
      type: dataToEdit ? dataToEdit.type : '',
      inputOrOutput: dataToEdit && dataToEdit.is_input ? 'input' : 'output',
      is_active: dataToEdit ? dataToEdit.is_active : true,
      is_intern: dataToEdit ? dataToEdit.is_intern : false,
      is_input: dataToEdit ? dataToEdit.is_input : false,
      attribute_data: dataToEdit ? dataToEdit.attribute_data : {},
      fatherAttributeId: dataToEdit ? dataToEdit.fatherAttributeId : null,
      description_text: dataToEdit ? dataToEdit.description_text : '',
      isMultilevelSelect: dataToEdit ? dataToEdit.isMultilevelSelect : false,
      multilevelType: dataToEdit ? dataToEdit.multilevelType : 'PADRE',
      AttributesLinkedListOptions: dataToEdit
        ? dataToEdit.AttributesLinkedListOptions
        : [],
      tooltipOrDescription:
        dataToEdit && dataToEdit.display_tooltip ? 'tooltip' : 'description',
      insuranceTypesHasAttributes:
        dataToEdit?.insuranceTypesHasAttributes?.map(
          (item) => item.clientType_has_insuranceType_id
        ) || []
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (formData, { resetForm }) => {
      const formDataWithFiles = new FormData()

      const updatedValues = {
        ...formData,
        is_input: formData.inputOrOutput === 'input',
        display_tooltip: formData.tooltipOrDescription === 'tooltip'
      }

      if (filesSelected.length) {
        filesSelected.forEach((file) => {
          formDataWithFiles.append('files', file)
        })
      }

      Object.keys(updatedValues).forEach((key) => {
        if (key === 'attribute_data') {
          formDataWithFiles.append(key, JSON.stringify(updatedValues[key]))
        } else if (key === 'AttributesLinkedListOptions') {
          formDataWithFiles.append(key, JSON.stringify(updatedValues[key]))
        } else if (key === 'insuranceTypesHasAttributes') {
          formDataWithFiles.append(key, JSON.stringify(updatedValues[key]))
        } else {
          formDataWithFiles.append(key, updatedValues[key])
        }
      })

      if (dataToEdit) {
        await updateAttributes.mutateAsync([dataToEdit.id, formDataWithFiles])
        dispatch(setAttributeDataToUpdate(null))
      } else {
        await addAttributes.mutateAsync(formDataWithFiles)
      }
      dispatch(setModalShow(false))
      resetForm()
      setfilesSelected([])
    }
  })

  const handleClose = () => {
    if (dataToEdit) {
      dispatch(setAttributeDataToUpdate(null))
    }
    dispatch(setModalShow(false))
    formik.resetForm()
  }

  const handleTypeChange = (option) => {
    formik.setFieldValue('type', option)
    if (option === 'Tabla') {
      formik.setFieldValue('attribute_data', {
        columns: [],
        rows: [],
        rowNumber: 1
      })
    }
    if (option === 'Rango numerico') {
      formik.setFieldValue('attribute_data', {
        min: 0,
        max: 1
      })
    }
    if (option === 'Vigencia') {
      formik.setFieldValue('attribute_data', {
        startDate: null,
        endDate: null
      })
    }
    if (option === 'Numerico') {
      formik.setFieldValue('attribute_data', {
        numberType: ''
      })
    }
    if (option === 'Rut') {
      formik.setFieldValue('attribute_data', {
        validate: false
      })
    }
    if (option === 'Lista predeterminada') {
      formik.setFieldValue('attribute_data', {})
    }

    if (option === 'Adjunto') {
      formik.setFieldValue('attribute_data', {
        max_file_size: 5,
        max_file: 5
      })
    }
  }

  const handleFileChange = (filesData) => {
    setfilesSelected(filesData)
  }

  const formSectionItemsCountIsGreaterThanZero =
    // eslint-disable-next-line no-underscore-dangle
    dataToEdit?._count?.FormSectionItem > 0

  const handleDisableMultiselectValue = (value) =>
    dataToEdit?.FormSectionItem?.some(
      (item) => item.formSection.form.clientType_has_insuranceType_id === value
    )

  return (
    <ModalBase
      opened={modalShow}
      onClose={handleClose}
      title="Ingresa la información del nuevo atributo aquí"
    >
      {formSectionItemsCountIsGreaterThanZero && (
        <Alert
          icon={<FiAlertCircle size="2rem" />}
          title="Alerta"
          color="yellow"
          style={{ marginBottom: '1rem' }}
        >
          Este atributo se encuentra en uso en un formulario por lo que sólo se
          pueden editar algunos campos.
        </Alert>
      )}
      <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Grid.Col span={12}>
            <TextInput
              placeholder="Nombre atributo"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Select
              placeholder="Tipo de Campo"
              name="type"
              data={attributesTypeOptions}
              value={formik.values.type}
              onChange={handleTypeChange}
              error={formik.touched.type && formik.errors.type}
              limit={10}
              disabled={formSectionItemsCountIsGreaterThanZero}
            />
          </Grid.Col>
          {formik.values.type === 'Texto' && (
            <>
              <Grid.Col span={12}>
                <Checkbox
                  label="Validación de campos"
                  checked={formik.values.attribute_data.validate}
                  onChange={(e) =>
                    formik.setFieldValue('attribute_data', {
                      validate: e.target.checked
                    })
                  }
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Select
                  placeholder="Tipo de validación"
                  data={['Email', 'Teléfono']}
                  value={formik.values.attribute_data.validation_type}
                  onChange={(value) =>
                    formik.setFieldValue(
                      'attribute_data.validation_type',
                      value
                    )
                  }
                />
              </Grid.Col>
            </>
          )}
          {formik.values.type === 'Adjunto' && (
            <>
              <Grid.Col span={12}>
                <NumberInput
                  defaultValue={5}
                  placeholder="12mb"
                  description="Establezcla el peso máximo del archivo en MB"
                  label="Maximo peso de archivo"
                  hideControls
                  value={formik.values.attribute_data.max_file_size}
                  onChange={(value) =>
                    formik.setFieldValue('attribute_data.max_file_size', value)
                  }
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <NumberInput
                  defaultValue={5}
                  placeholder="12mb"
                  description="Establezcla la cantidad maxima de achivos permitidos"
                  label="Archivos permitidos"
                  hideControls
                  value={formik.values.attribute_data.max_file}
                  onChange={(value) =>
                    formik.setFieldValue('attribute_data.max_file', value)
                  }
                />
              </Grid.Col>
            </>
          )}
          {formik.values.type === 'Formula' && (
            <AttributesFormulaView formik={formik} />
          )}
          {formik.values.type === 'Tabla' && (
            <AttributesTableView formik={formik} />
          )}
          {formik.values.type === 'Rango numerico' && (
            <AttributesNumericRangeView formik={formik} />
          )}
          {formik.values.type === 'Vigencia' && (
            <AttributesDateRangeView formik={formik} />
          )}
          {formik.values.type === 'Numerico' && (
            <AttributesNumericView formik={formik} />
          )}
          {formik.values.type === 'Rut' && (
            <Checkbox
              label="Validación de rut"
              checked={formik.values.attribute_data.validate}
              onChange={(e) =>
                formik.setFieldValue('attribute_data', {
                  validate: e.target.checked
                })
              }
            />
          )}
          {formik.values.type === 'Lista predeterminada' && (
            <AttributesDefaultListView
              formik={formik}
              dataToEdit={dataToEdit}
            />
          )}
          {formik.values.type === 'Archivo cargado' && (
            <>
              <Grid.Col span={12}>
                <FileInput
                  label="Seleccione los archivos"
                  value={filesSelected}
                  onChange={handleFileChange}
                  placeholder="Click para seleccionar los archivos"
                  multiple
                  icon={<MdUploadFile size={14} />}
                  clearable
                />
              </Grid.Col>
              {dataToEdit?.attribute_data?.files && (
                <Grid.Col span={12}>
                  <ul>
                    {dataToEdit.attribute_data.files.map((file) => (
                      <li key={file.key}>
                        <a href={file.url} target="_blank" rel="noreferrer">
                          {file.key}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Grid.Col>
              )}
            </>
          )}
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>

          <Grid.Col span={12}>
            <Center>
              <Radio.Group
                value={formik.values.inputOrOutput}
                onChange={(newValue) =>
                  formik.setFieldValue('inputOrOutput', newValue)
                }
                spacing="xl"
              >
                <Radio
                  value="input"
                  label="Entrada"
                  disabled={formSectionItemsCountIsGreaterThanZero}
                />
                <Radio
                  value="output"
                  label="Salida"
                  disabled={formSectionItemsCountIsGreaterThanZero}
                />
              </Radio.Group>
            </Center>
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>

          <Grid.Col span={12}>
            <Textarea
              placeholder="Descripción"
              name="description_text"
              onChange={formik.handleChange}
              value={formik.values.description_text}
              error={formik.errors.description_text}
            />
            <Center>
              <Radio.Group
                value={formik.values.tooltipOrDescription}
                onChange={(newValue) =>
                  formik.setFieldValue('tooltipOrDescription', newValue)
                }
                spacing="xl"
              >
                <Radio value="tooltip" label="Tooltip" />
                <Radio value="description" label="Ayuda en input" />
              </Radio.Group>
            </Center>
          </Grid.Col>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
          <Grid.Col xs={12}>
            <Center>
              <Switch
                size="md"
                labelPosition="left"
                label="Interno"
                color="green"
                name="is_intern"
                checked={formik.values.is_intern}
                onChange={formik.handleChange}
                value={formik.values.is_intern}
                error={formik.errors.is_intern}
              />
            </Center>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Center>
              <Switch
                size="md"
                labelPosition="left"
                label="Activo"
                color="green"
                name="is_active"
                checked={formik.values.is_active}
                onChange={formik.handleChange}
                value={formik.values.is_active}
                error={formik.errors.is_active}
              />
            </Center>
          </Grid.Col>
          <Grid.Col span={12}>
            <MultiSelect
              placeholder="Tipos de seguros"
              name="clientTypes"
              valueComponent={({ value, onRemove, label }) => (
                <Badge
                  m={3}
                  variant="outline"
                  rightSection={
                    <ActionIcon
                      size="xs"
                      radius="xl"
                      variant="transparent"
                      disabled={handleDisableMultiselectValue(value)}
                      onClick={onRemove}
                    >
                      <FiX size={16} />
                    </ActionIcon>
                  }
                >
                  {label}
                </Badge>
              )}
              value={formik.values.insuranceTypesHasAttributes}
              searchable
              dropdownPosition="flip"
              nothingFound="Sin resultados"
              onChange={(option) => {
                formik.setFieldValue('insuranceTypesHasAttributes', option)
              }}
              data={
                data?.data
                  ? data?.data
                      .filter((t) => t.insuranceTypes.length)
                      .map((c) => {
                        const b = []
                        c.insuranceTypes.forEach((it) => {
                          const opt = {
                            value: it.id,
                            label: `${it.insuranceType.name} (${c.name})`,
                            group: c.name
                          }
                          b.push(opt)
                        })
                        return b.flat()
                      })
                      .flat()
                  : []
              }
              error={formik.errors.clientTypes}
            />
          </Grid.Col>
          <Grid.Col xs={12}>
            <Center>
              <Button
                loading={addAttributes.isLoading || updateAttributes.isLoading}
                loaderPosition="right"
                type="submit"
              >
                Guardar
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      </Box>
    </ModalBase>
  )
}

export default AttributesForm
